import useUser from '~/contexts/user/useUser'
import useTranslator from '~/lib/useTranslator'
import InternalLink from './InternalLink'
import { useEffect, useRef, useState } from 'react'
import { useNavigation } from '@remix-run/react'
import DownloadButtons from './DownloadButtons'

function bgColor(color: 'green' | 'purple', isOpen: boolean) {
  return isOpen ? 'bg-premium-color' : color === 'green' ? 'bg-primary-color' : 'bg-secondary-color'
}

export default function Navigation({ color = 'green' }: { color?: 'green' | 'purple' }) {
  const Translator = useTranslator()
  const user = useUser()
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const navigation = useNavigation()

  useEffect(() => {
    if (navigation.state) {
      setIsOpen(false)
    }
  }, [navigation.state])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.transition = 'background-color 0.3s ease'
    }
  }, [isOpen])

  return (
    <div
      className={[
        'w-screen relative z-10 flex flex-1 border-b-2 border-b-white border-opacity-20',
        bgColor(color, isOpen),
      ].join(' ')}
    >
      <div
        ref={containerRef}
        className={[
          'max-w-screen-xl mx-auto py-4 md:py-6 px-4 flex flex-1 justify-between items-center z-20 w-full',
          bgColor(color, isOpen),
        ].join(' ')}
      >
        <InternalLink to="/" className="flex flex-1">
          <img
            className="fill-white -mb-2"
            width={82}
            height={62}
            src="/images/heja-logo-white.svg"
            alt="Heja logo"
          />
        </InternalLink>

        <ul
          className={[
            'absolute top-20 left-0 right-0 md:relative md:top-auto flex flex-1 justify-end flex-col w-full md:flex-row shadow-2xl md:shadow-none py-4 gap-2 md:py-0 md:gap-0',
            bgColor(color, isOpen),
            isOpen ? 'block' : 'hidden md:flex',
          ].join(' ')}
        >
          <NavLink to="/">{Translator.singular('Home')}</NavLink>
          <NavLink to="/pricing" prefetch="intent">
            {Translator.singular('Pricing')}
          </NavLink>
          <NavLink to="/partners" prefetch="intent">
            {Translator.singular('Partners')}
          </NavLink>
          <NavLink to="https://help.heja.io">{Translator.singular('Help')}</NavLink>
          {!user?._id ? (
            <NavLink
              to={'https://web.heja.io/'}
              className="bg-white/20 rounded-lg text-opacity-100 font-normal md:ml-4 px-5 hover:opacity-80 inline-block text-nowrap"
            >
              {Translator.singular('Sign in')} &raquo;
            </NavLink>
          ) : (
            <>
              <NavLink to="/pro" prefetch="intent">
                {Translator.singular('Pro')}
              </NavLink>
              <NavLink to="https://web.heja.io/">{Translator.singular('Desktop')}</NavLink>
            </>
          )}
          <li className="flex flex-col flex-1 items-center md:hidden">
            <DownloadButtons />
          </li>
        </ul>

        <div className="md:hidden">
          <button type="button" className="text-center p-4" onClick={() => setIsOpen(!isOpen)}>
            <svg className={!isOpen ? 'block w-4 h-4' : 'hidden'}>
              <use xlinkHref="#menu-open" />
            </svg>
            <svg className={isOpen ? 'block w-4 h-4' : 'hidden'}>
              <use xlinkHref="#menu-close" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

function NavLink({
  className,
  to,
  children,
  prefetch,
}: {
  className?: string
  to: string
  prefetch?: 'intent' | 'render'
  children: React.ReactNode
}) {
  return (
    <li className="text-center">
      <InternalLink
        to={to}
        prefetch={prefetch}
        className={[
          'text-white text-2xl text-opacity-70 py-2 px-4 block z-50 hover:text-opacity-100',
          className,
        ].join(' ')}
      >
        {children}
      </InternalLink>
    </li>
  )
}
